<template>
  <div>
  <slot name="useInsideUl"></slot>
</div>
</template>

<script>
export default {
   

}
</script>

<style scoped>

</style>